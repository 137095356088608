var vm = new Vue({
    el: '#MainWrapper',
    data: {
        imageSize: {},
        isDesktop: false,
        isMobileLandscape: false,
        isMobilePortrait: false,
        isTabletLandscape: false,
        isTabletPortrait: false,
        modalMerchandiseSubtotal: 0,
        modalTotalItems: 0,
        modalDisplayName: '',
        modalImageUrl: '',
        modalQuantity: 0,
        modalSubtotal: 0,
        modalHeading: 'Added to Cart!',
        modalButtonText: 'Secure Checkout',
        imageWidth: {
            addToCartModal: 100
        },
        resizerParam: '?width='


    },
    mounted() {
        document.addEventListener('DOMContentLoaded', this.handleEvent);
        window.addEventListener('resize', this.handleEvent);
    },
    methods: {
        getImageSelectors() {
            const displayModes = [
                'block:not([class*="displaymode-"])',
                'displaymode-full',
                'displaymode-half',
                'displaymode-one-quarter',
                'displaymode-one-third',
                'displaymode-three-quarters',
                'displaymode-two-thirds',
                'mt_slide__img'
            ];

            return displayModes.map(mode => `.${mode} img`);
        },
        getImageSizes() {
            return Object.values(this.imageSize);
        },
        handleEvent() {
            this.setDeviceFlags();
            this.setImageWidths();
            this.setImageUrls();
        },
        setDeviceFlags() {
            const mobileRegex = /Android|webOS|iP(hone|od)|BlackBerry|IEMobile|Opera Mini/i;
            const tabletRegex = /(Android(?!.*mobile))|iPad|Kindle|Playbook|Silk|(Puffin(?!.(IP|AP|WP)))|Tablet|(Windows(?!.*phone)(?!.*NT)(.touch))/i;
            const userAgent = window.navigator.userAgent;
            const isMobile = mobileRegex.test(userAgent);
            const isTablet = tabletRegex.test(userAgent);
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;
            const isLandscape = viewportWidth > viewportHeight;

            this.isDesktop = !isMobile && !isTablet;
            this.isMobileLandscape = isMobile && isLandscape;
            this.isMobilePortrait = isMobile && !isLandscape;
            this.isTabletLandscape = isTablet && isLandscape;
            this.isTabletPortrait = isTablet && !isLandscape;
        },
        setImageUrls: function () {
            const imageSelectors = this.getImageSelectors();
            const imageSizes = this.getImageSizes();

            imageSelectors.forEach((imageSelector) => {
                this.imageSize = imageSizes.shift();

                document.querySelectorAll(imageSelector).forEach((imageElement) => {
                    const urlAttribute = imageElement.hasAttribute('src') ? 'src' : 'data-src';
                    let url = imageElement.getAttribute(urlAttribute);

                    if (url && ['/products/', '/homepage-images/'].some(substring => url?.includes(substring))) {
                        const resizerParam = '?width=';
                        const newUrl = url.includes('?') ? url.replace(/\?width=\d+/, `${resizerParam}${this.imageSize}`) : `${url}${resizerParam}${this.imageSize}`;

                        if (imageElement.hasAttribute('src')) {
                            imageElement.setAttribute('src', newUrl);
                        }
                        if (imageElement.hasAttribute('data-src')) {
                            imageElement.setAttribute('data-src', newUrl);
                        }
                    }
                });
            });
        },
        setImageWidths() {
            const imageSizes = {
                desktopTabletLandscape: {
                    auto: 1140,
                    full: 1140,
                    half: 555,
                    quarter: 263,
                    third: 360,
                    threeQuarters: 848,
                    twoThirds: 750,
                    mt: 175
                },
                mobileLandscapeTabletPortrait: {
                    auto: 720,
                    full: 720,
                    half: 345,
                    quarter: 345,
                    third: 220,
                    threeQuarters: 720,
                    twoThirds: 470,
                    mt: 96
                },
                mobilePortrait: {
                    auto: 384,
                    full: 384,
                    half: 384,
                    quarter: 177,
                    third: 384,
                    threeQuarters: 384,
                    twoThirds: 384,
                    mt: 131
                }
            };

            if (this.isMobilePortrait) {
                Object.assign(this.imageSize, imageSizes.mobilePortrait);
            } else if (this.isDesktop || this.isTabletLandscape) {
                Object.assign(this.imageSize, imageSizes.desktopTabletLandscape);
            } else if (this.isMobileLandscape || this.isTabletPortrait) {
                Object.assign(this.imageSize, imageSizes.mobileLandscapeTabletPortrait);
            }
        },
        addToCartMonetate(skuCode) {
            var viewModel = {
                SkuCode: skuCode,
                Quantity: 1,
                Alias: null
            };

            $.ajax({
                method: "POST",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(viewModel),
                dataType: "json",
                url: '/api/CartItem',
                headers: { "__RequestVerificationToken": $("input[name='__RequestVerificationToken']").val() }
            })
                .then(data => {
                    this.modalMerchandiseSubtotal = data.MerchandiseSubtotal;
                    this.modalTotalItems = data.TotalNumberOfItems;
                    this.modalDisplayName = data.AddedCartItem.DisplayName.trim();
                    this.modalImageUrl = data.AddedCartItem.ImageUrl;
                    this.modalQuantity = data.AddedCartItem.Quantity;
                    this.modalSubtotal = data.AddedCartItem.Subtotal;


                    // update cart header count
                    cei.shared.cart.updateCartHeaderQuantity(1);
         
                    $("#mt-add-to-cart-modal").modal('show');

                })
                .catch(error => {
                    console.error;
                });
        }

    }

});
